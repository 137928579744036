.wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #030d1d;
    width: 100%;
    height: 100%;
    position: absolute;
}

.layoutBg {
    position: absolute;
    width: 100%;
    height: 100%;
}

.layoutBg svg {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    /* z-index: -1; */
}

.buttonWrapper {
    position: absolute;
    bottom: 3.5%;
    left: auto;
    display: flex;
    justify-content: space-between;
    width: 26%;
}