.screenTitle {
    position: absolute;
    top: 9.5%;
    left: 13%;
    z-index: 1;
}

.aboutButton {
    position: absolute;
    top: 15%;
    left: 3%;
    z-index: 1;
}

.structureButton {
    position: absolute;
    top: 27%;
    left: 3%;
    z-index: 1;
}

.mksButton {
    position: absolute;
    bottom: 8.5%;
    left: 4%;
    z-index: 1;
}

.researchButton {
    position: absolute;
    top: 15%;
    right: 3%;
    z-index: 1;
}

.videoButton {
    position: absolute;
    top: 27%;
    right: 3%;
    z-index: 1;
}

.scienceButton {
    position: absolute;
    bottom: 8.5%;
    right: 4%;
    z-index: 1;
}

.iframeContainer {
    position: absolute;
    top: auto;
    left: auto;
    background-color: #030d1d;
    width: 93.75%;
    height: 74%;
    /* width: 1800px;
    height: 800px; */
}

.iframeContainer iframe{
   width: 100%;
   height: 100%;
}

.soundButton {
    position: absolute;
    bottom: 24%;
    right: calc(4% + 32.5px);
    z-index: 1;
    cursor: pointer;
    transition: all 0.3s;
}

.soundButton:hover {
    transition: all 0.3s;
    transform: scale(1.15);
}