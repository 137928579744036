@keyframes scale-up-center {
    0% {
        -webkit-transform: scale(0.5);
                transform: scale(0.5);
    }
    100% {
        -webkit-transform: scale(1);
                transform: scale(1);
    }
}

.wrapper {
    /* display: flex; */
    justify-content: center;
    align-items: center;
    background-color: rgba(0, 0, 0, 0.7);
    /* position */
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 10;
}

.container {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 1736px;
    height: 860px;
    /* width: 90.4%;
    height: 79.6%; */
    position: relative;
    animation: scale-up-center 0.8s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
}

.bgImage {
    width: 100%;
    height: 100%;
    background-color: #000000;
    border-radius: 30px;
    border: 1px solid #3C70BE;
}

.closeButton {
    position: absolute;
    top: 60px;
    right: 60px;
    cursor: pointer;
}
.closeButton svg{
    transition: all 0.3s;
}

.closeButton:hover svg {
    transition: all 0.3s;
    transform: scale(1.4);
}

.content {
    position: absolute;
    /* width: 1300px;
    height: 800px; */
    width: 1500px;
    height: 700px;
    display: flex;
    justify-content: space-between;
}

.aboutContent {
    position: absolute;
    width: 1300px;
    height: 700px;
}

.aboutText {
    /* text */
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    letter-spacing: 0.07em;
    color: #FFFFFF;
}


.structureItem {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 10px;
    margin-right: 5%;
    width: 45%;
    float: left;
    cursor: pointer;
}

.structureItem .aboutText {
    transition: all 0.3s;
}

.structureItem:hover .aboutText {
    transition: all 0.3s;
    font-size: 18px;
}


.aboutNumber {
    position: absolute;
    /* text */
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 700;
    font-size: 17px;
    letter-spacing: 0.07em;
    color: #0066FF;
}

.contentSlider {
    width: 47%;
    height: 100%;
    position: relative;
}

.contentText {
    width: 47%;
    height: 100%;
    /* overflow-y: auto; */
}

.contentTextCentered {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 47%;
    height: 100%;
    /* overflow-y: auto; */
}

.moduleTitle {
    margin-bottom: 25px;
    /* text */
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 600;
    font-size: 21px;
    line-height: 32px;
    letter-spacing: 0.05em;
    color: #3C70BE;
}