.wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 151px;
    height: 90px;
    cursor: pointer;
    position: relative;
    transition: all 0.3s;
}
.wrapper:hover  {
    transition: all 0.3s;
    transform: scale(1.1);
}

.wrapper svg {
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: 2;
}

.text {
    /* text */
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 400;
    font-size: 13px;
    line-height: 18px;
    text-align: center;
    letter-spacing: 0.07em;
    color: #FFFFFF;
}

.buttonContent {
    display: flex;
    width: 100%;
    height: 100%;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}