.videoWrapper {
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    right: 0;
    width: 100%;
    height: 100%;
    z-index: 100;
    object-fit: fill;
}

.closeButton {
    position: absolute;
    top: 60px;
    right: 60px;
    cursor: pointer;
    z-index: 150;
}

/* .closeButton:hover svg {
    transition: all 0.3s;
    transform: scale(1.2);
} */

.blackBg {
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    right: 0;
    z-index: 99;
    background-color: #000000;
}