.textSwiper {
    width: 100%;
    height: 100%;
}
.swiperSlide {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
}