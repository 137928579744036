.text {
    margin-bottom: 40px;
    /* text */
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 600;
    font-size: 26px;
    line-height: 43px;
    letter-spacing: 0.05em;
    color: #3C70BE;
}