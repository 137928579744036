.swiper {
    width: 100%;
    height: 100%;
}


.swiperSlide {
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: flex-start;
}
  
.swiperSlide img {
    display: block;
    width: 100%;
    height: auto;
    object-fit: cover;
}